<template>
  <div v-if="show" class="rulesV1" @touchmove.prevent>
    <div class="l-container">
      <div
        class="rulesV1_content l-pa"
        :class="{ 'rulesV1_content-show': classShow }"
      >
        <div class="rulesV1_goods">
          <img :src="cover" />
          <div>
            <div>
              <span>{{ price }}</span>
              <img
                :src="require('@STATIC/image/icons/closeFill-gray.png')"
                @click="close"
              />
            </div>
            <div class="l-boxs box-p-t-8 text-ellipsis">
              已选择：{{ rulesInfo }}
            </div>
            <div class="box-m-t-18 l-flex l-flexm-b">
              <span class="rulesV1_sales"> 库存：{{ stockNum }} </span>
              <stepperV1
                :value="num"
                :min="1"
                :max="stockNum"
                @onChange="onStep"
              ></stepperV1>
            </div>
          </div>
        </div>
        <div class="rulesV1_rule">
          <div class="l-flexv" v-for="(specs, idx) in goods_specs" :key="idx">
            <div class="rulesV1_rule_label">{{ specs.title }}</div>
            <div class="l-flexh" style="flex-wrap:wrap">
              <div
                class="rulesV1_rule_item"
                :class="{
                  'rulesV1_rule_item-active':
                    spec_checkeds[specs.id] == item.id,
                }"
                v-for="(item, index) in specs.spec_items"
                :key="index"
                @click="selectRule(idx, index)"
                >{{ item.title }}
              </div>
            </div>
          </div>
        </div>
        <div class="rulesV1_foot" v-debounce="['click', confirm, 500]">
          确定
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import stepperV1 from "@T/community/stepper/stepperV1.vue";
export default {
  name: "rulesV1",
  components: { stepperV1 },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    //封面
    cover: {
      type: String,
      default: "",
    },
    //单价
    unit_price: {
      type: [Number, String],
      default: 0,
    },
    //库存
    stock: {
      type: Number,
      default: 0,
    },
    //选择的数量
    number: {
      type: [String, Number],
      default: 1,
    },
    //规格详情
    goods_products: {
      type: [Object, Array],
      default() {
        return {};
      },
    },
    //规格列表
    goods_specs: {
      type: Array,
      default() {
        return [];
      },
    },
    //已经选择的规格
    goods_spec_checkeds: {
      type: Object,
      default() {
        return {};
      },
    },
    //关联的商品信息
    goods_containers: {
      type: Object,
      default: () => {
        return { }
      }
    }
  },
  data() {
    return {
      classShow: false,
      num: 1, //选择的数量默认是1
      spec_checkeds: {}, //所选择的规格
      goodsContainer:{}
    };
  },
  created() {
    setTimeout(() => {
      this.classShow = this.$props.show;
    }, 1);
    this.goodsContainer = this.$props.goods_containers
  },
  watch: {
    goods_specs() {
      this.initSpecCheckeds();
    },
    goods_containers: {
      handler(newV) {
        this.goodsContainer = newV
      }
    },
    show() {
      this.$nextTick(() => {
        this.classShow = this.$props.show;
      });
      this.num = this.$props.number;
      this.initSpecCheckeds();
    },
    goods_spec_checkeds() {
      this.spec_checkeds = this.$props.goods_spec_checkeds;
    },
    number() {
      this.num = this.$props.number;
    },
    selectRule() {
      this.num = 1;
    },
  },
  computed: {
    price() {
      if (this.$props.goods_specs.length > 0) {
        return this.$props.goods_products[this.speckey].market_price;
      }
      return this.$props.unit_price;
    },
    stockNum() {
      if (this.$props.goods_specs.length > 0) {
        return this.$props.goods_products[this.speckey].stock_number;
      }
      return this.$props.stock;
    },
    speckey() {
      let list = Object.values(this.spec_checkeds);
      let spec_key = "";
      for (const key in this.$props.goods_products) {
        if (Object.hasOwnProperty.call(this.$props.goods_products, key)) {
          const element = this.$props.goods_products[key];
          if (element.specs.length != list.length) {
            continue;
          }

          let isHas = list.every((curr) => {
            return key.includes(String(curr));
          });
          if (isHas) {
            spec_key = element.specs.join(",");
            break;
          }
        }
      }
      return spec_key;
    },
    rulesInfo() {
      let list = Object.values(this.spec_checkeds);
      if (list.length > 0) {
        return this.$props.goods_products[this.speckey].title;
      }
      return "";
    },
  },
  methods: {
    initSpecCheckeds() {
      if (Object.keys(this.$props.goods_spec_checkeds).length === 0) {
        let spec_checkeds = {};
        this.$props.goods_specs.forEach((val) => {
          // console.log(val);
          Object.assign(spec_checkeds, {
            [val.id]: val.spec_items[0].id,
          });
        });
        this.spec_checkeds = spec_checkeds;
      }
    },
    onStep(params) {
      // console.log(params);
      this.num = params.value;
    },
    close() {
      this.$emit("onClose", false);
    },
    selectRule(idx, index) {
      
      let checkeds = this.spec_checkeds;
      let list = JSON.parse(JSON.stringify(this.goods_specs));
      Object.assign(checkeds, {
        [list[idx].id]: list[idx].spec_items[index].id,
      });

      this.spec_checkeds = checkeds;
      //增加判断，如果规格中包含了商品ID，则表示需要动态切换商品信息
      let goodsId = list[idx].spec_items[index]?.goods_id || 0
      let goods
      if (typeof this.goodsContainer[goodsId] != 'undefined') {
        goods = this.goodsContainer[goodsId]  
        this.$emit('changeGoodsInfo',goods)
      }
      
    },
    confirm() {
      this.close();
      if (this.stockNum == 0) {
        this.$toast.text("库存不足", 1000);
        return;
      }
      this.$emit("onConfirm", {
        specs: this.spec_checkeds || {},
        num: this.num,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@include b(rulesV1) {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  @include e(content) {
    bottom: 0;
    width: 375px;
    // height: 0;
    box-sizing: border-box;
    padding: 20px;
    background: #ffffff;
    border-radius: 34px 34px 0px 0px;
    transition: height 1s ease;
    box-sizing: border-box;
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
    display: flex;
    flex-direction: column;
    // @include m(show) {
    //   minHeight: calc(380px + constant(safe-area-inset-bottom));
    //   minheight: calc(380px + env(safe-area-inset-bottom));
    // }
  }
  @include e(goods) {
    width: 100%;
    height: 76px;
    display: flex;
    & > img {
      flex-shrink: 0;
      width: 76px;
      height: 76px;
    }
    & > div {
      height: 100%;
      box-sizing: border-box;
      padding-left: 12px;
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      & > div {
        display: flex;
        align-items: center;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #939393;
        &:first-of-type {
          justify-content: space-between;
          font-size: 20px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #c10000;
          & > span {
            &::before {
              content: "￥";
              font-size: 14px;
            }
          }
          & > img {
            width: 20px;
            height: 20px;
          }
        }
      }
    }
  }
  @include e(rule) {
    box-sizing: border-box;
    padding: 10px 0 15px 0;
    width: 100%;
    min-height: 227px;
    // height: 227px;
    // overflow: hidden;
    // overflow-y: scroll;
    @include e(label) {
      margin-top: 12px;
      font-size: 14px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
    }
    @include e(item) {
      margin-top: 12px;
      margin-right: 10px;
      box-sizing: border-box;
      padding: 11px 16px;
      border-radius: 3px;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333;
      background: #f6f6f6;
      @include m(active) {
        box-shadow: inset 0 0 1px 1px #ff8116;
        color: #ff8116 !important;
        background: transparent !important;
      }
    }
  }
  @include e(foot) {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 345px;
    height: 44px;
    background: linear-gradient(139deg, #fac484 0%, #f46a17 100%);
    border-radius: 3px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
  }
}
</style>
