import { render, staticRenderFns } from "./rulesV1.vue?vue&type=template&id=461dccc0&scoped=true&"
import script from "./rulesV1.vue?vue&type=script&lang=js&"
export * from "./rulesV1.vue?vue&type=script&lang=js&"
import style0 from "./rulesV1.vue?vue&type=style&index=0&id=461dccc0&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "461dccc0",
  null
  
)

export default component.exports